.product-card {
    position: relative;
    min-width: 280px;
    max-width: 280px;
    /* min-height: 370px;
    max-height: 370px; */
    text-decoration: none;
    color: var(--color-dark-1);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-light-3);
    box-shadow: 0px 3px 10px 0px #ccc4;
    margin:2rem;
    transition: border-color 0.2s ease;
    border-radius: 2px;
    overflow:hidden;
}
.product-card:hover {
    border-color: var(--color-primary);
}
.product-card .top {
    max-height: 200px;
    min-height: 200px;
    background-color: var(--color-dark-2);
}
.product-card .bottom {
    padding: 0.75rem;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-card .top img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
.product-card .title, .product-card .bottom p {
    margin: 0.25rem 0;
}

.profile-section {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin:0.5rem 0 0.75rem;
}

.profile-section > div {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 0.75rem;
}

.profile-section > div > img { 
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    
}


.product-card .price {
    font-weight: 500;
    font-size: 1.1rem;
    text-align: right;
    color: var(--color-secondary);
}


.edit-btn {
    position: absolute;
    top:0;
    right:0;
    /* border:1px solid var(--color-light-3); */
    background-color: var(--color-primary);
    color:var(--color-light-1);
    padding:0.4rem;
    box-sizing: border-box;
    cursor:pointer;
    border-bottom-left-radius: 4px;
    transform: translateY(-100%);
    transition: transform 0.2s ease;
}

.delete-btn {
    position: absolute;
    top:0;
    left:0;
    /* border:1px solid var(--color-light-3); */
    background-color: var(--color-danger);
    color:var(--color-light-1);
    padding:0.4rem 0.6rem;
    box-sizing: border-box;
    cursor:pointer;
    border-bottom-right-radius: 4px;
    transform: translateY(-100%);
    transition: transform 0.2s ease;
    border:none;
    font-size: 1rem;
}

.product-card:hover .edit-btn, .product-card:hover .delete-btn  {
    transform: translateY(0);
}

.product-card .description {
    color: var(--color-dark-4);
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative; 
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em; 
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;

    /* */
    margin-right: -1em;
    padding-right: 1em;
}


.product-card .description:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
}
.product-card .description:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    width: 1em;
    /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: white;
}
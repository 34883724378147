/*====================
      Variables
======================*/
:root {
    --color-dark-1: #403D52;
    --color-dark-2: #656276;
    --color-dark-3: #57536C;
    --color-dark-4: #82808c;
    --color-primary: #648DE5;
    --color-secondary: #45B69C;
    --color-danger: #F26666;
    --color-light-1: #fff;
    --color-light-2: #F7F7F7;
    --color-light-3: #EFEFF0;
    --color-light-4: #ddd;
}

/*====================
        Main
======================*/

body {
    font-family: "Roboto";
    color: var(--color-dark-1);
    margin: 0;
    padding:0;
}

::selection {
    background-color: var(--color-secondary);
    color: var(--color-light-1);
}

.section {
    max-width: 1350px;
    margin: 0 auto;
    padding:0 1rem;
    display:block;
    box-sizing: border-box;
}
.container {
    padding: 1rem;
}
.container.dark {
    background-color: var(--color-dark-1);
}
.container.light-grey {
    background-color: var(--color-light-2);
}
.container.gradient {
    background: linear-gradient(to bottom right, var(--color-dark-1), var(--color-dark-3));
}



/*====================
        Footer
======================*/
footer {
    background-color: var(--color-light-2);
    color: var(--color-dark-2);
}
footer > div.section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    flex-wrap: wrap;
    
}
footer > .section > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
footer > .section > div > span {
    margin: 0.2rem 0 0 0.5rem;
}

/*====================
        Text
======================*/

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat";
}
h1 { font-size:2.5rem; }
h2 { font-size:1.8rem; }
h4 { font-size:1.2rem; }

.text-center {
    text-align: center;
}
.text-white {
    color:var(--color-light-1);
}


/*====================
        Button
======================*/
.btn {
    font-family: "Roboto";
    font-style: normal;
    color: var(--color-dark-1);
    text-decoration: none;
    border-radius: 4px;
    padding: 0.6rem 1rem;
    box-sizing: border-box;
    margin: 0.5rem 0;
    width:fit-content;
    display:block;
    border:none;
    font-size: 0.95rem;
    transition:background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
}
.btn:hover:not(:disabled) {
    cursor:pointer;
}

.btn.center {
    margin-left: auto;
    margin-right: auto;

}
.btn.wide {
    width:100%;
    margin-left:auto;
    margin-right:auto;
}

.btn.blue {
    color: var(--color-light-1);
    background-color:var(--color-primary);
}
.btn.blue:hover:not(:disabled) {
    background-color:#517EE1;
}
.btn.blue-outline {
    color: var(--color-primary);
    border:2px solid var(--color-primary);
    background-color: transparent;
}
.btn.blue-outline:hover:not(:disabled) {
    background-color:var(--color-primary);
    color:var(--color-light-1);
}
.btn.blue-outline.text-white {
    color: var(--color-light-1);
}

.btn.green {
    color: var(--color-light-1);
    background-color:var(--color-secondary);
}
.btn.green:hover:not(:disabled) {
    background-color:#3EA38B;
}
.btn.green-outline {
    color: var(--color-secondary);
    border:2px solid var(--color-secondary);
    background-color: transparent;
}
.btn.green-outline:hover:not(:disabled) {
    background-color:var(--color-secondary);
    color:var(--color-light-1);
}
.btn.green-outline.text-white {
    color: var(--color-light-1);
}

.btn.danger {
    color: var(--color-light-1);
    background-color:var(--color-danger);
}
.btn.danger:hover:not(:disabled) {
    background-color:#EF4343;
}
.btn.danger-outline {
    color: var(--color-danger);
    border:2px solid var(--color-danger);
    background-color: transparent;
}
.btn.danger-outline:hover:not(:disabled) {
    background-color:var(--color-danger);
    color:var(--color-light-1);
}
.btn.danger-outline.text-white {
    color: var(--color-light-1);
}

.btn:disabled {
    color: var(--color-light-4);
    background-color: var(--color-dark-4);
    cursor:default;
}

.btn.blue-outline:disabled {
    color: var(--color-primary);
    background-color: transparent;
    opacity:0.5;
}
/*====================
        Input
======================*/
.input {
    font-family: "Roboto";
    padding:0.5rem 0.6rem;
    width: 100%;
    outline:none;
    border-radius: 4px;
    border: 2px solid var(--color-light-4);
    background-color: var(--color-light-2);
    color: var(--color-dark-1);
    font-size: 0.9rem;
    box-sizing: border-box;
}
.input.disabled {
    color: var(--color-dark-4);
    cursor:not-allowed;
}
.input.search {
    background-color: var(--color-light-1);
    border-color: var(--color-light-1);
}
.input:focus {
    border:2px solid var(--color-primary);
}
.input.search:focus {
    border:2px solid var(--color-light-1);
}
textarea.input {
    resize: none;
    height:6rem;
}
select.input {
    color: var(--color-dark-1);
}
.input-block {
    margin:0.6rem 0.5rem;
}
.form-block {
    margin:0.4rem 0;
}
.input-label{
    margin-bottom:0.3rem;
    display:block;
}

.combo-input {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.combo-input .input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.error-message {
    color: var(--color-danger);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.hidden {
    visibility: hidden;
}

.file-input {
    display:block;
    margin:0.5rem;
}
.file-input > input {
    display:none;
}

.search-form {
    display:flex;
    flex-direction: row;
    max-width:500px;
    margin-top:0.5rem;
}
.search-form.center {
    margin:1.5rem auto;
}

.search-form > button {
    border:none;
    border-left:1px solid var(--color-primary);
    background-color: var(--color-light-1);
    color: var(--color-primary);
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor:pointer;
    padding:0 0.75rem;
}

#searchField {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

input.input[type="color"] {
    width: 80px;
    padding: 0;
    height: 30px;
    margin: 0.5rem 0;
}
/*====================
        Image
======================*/
img.round {
    border-radius: 100%;
}
.quant-logo {
    height:45px;
    box-sizing: border-box;
    padding:0.25rem 0;
}
/*====================
        Modal
======================*/
.screen {
    display:none;
    position:fixed;
    z-index: 28;
    width:100%;
    height:100%;
    background-color: #0004;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor:pointer;
}
.modal {
    display:none;
    position:absolute;
    z-index: 30;
    width:90%;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    max-width:750px;
    background-color: var(--color-light-1);
    padding:1rem;
    box-sizing: border-box;
}
.screen.visible, .modal.visible {
    display:block;
}

.modal .modal-header {
    display:flex;
    justify-content: space-between;
    flex-direction: row;
}
.modal .modal-header h2 {
    margin:0.5rem 0;
}
.modal .modal-header button, .cancel-btn {
    background-color: transparent;
    border:none;
    outline:none;
    font-weight: bold;
    color: var(--color-dark-1);
    cursor:pointer;
    transition: color 0.1s ease;
    display:flex;
    font-size: 1.2rem;
}
.modal .modal-header button:hover {
    color: var(--color-danger);
}
/*====================
      Image Modal
======================*/

#imageModal {
    padding:0;
    position: fixed;
}
#imageModal > .modal-header {
    padding: 1rem 1rem 0;
}
#imageModal .btn-group {
    border-top: 1px solid #ccc;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem;
}
#imageModal .image-options {
    padding: 0 1rem;
    display:flex;
}
#imageModal .image-options  .image-option {
    padding: 0.5rem 1rem;
    cursor:pointer;
}
#imageModal .image-options input:checked + .image-option {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}
#imageModal .image-options-container {
    border-bottom: 1px solid #ccc;
}
.modal input[type="radio"], .modal input[type="checkbox"] {
    display:none;
}
.upload-container {
    padding: 1rem;
    height:300px;
    overflow-x: hidden;
}
.upload-container.hidden {
    display:none;
}
.image-upload-container {
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.image-upload-container p {
    color: var(--color-dark-2);
    margin:0.5rem 0 0;
}
.image-upload-container .input-label.file-input.btn {
    margin:0.25rem auto;
}
.image-select-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.image-select-option {
    width:125px;
    height:125px;
    overflow:hidden;
    margin:0.5rem;
    border:2px solid #ccc;
    cursor:pointer;
}
.image-select-option.selected {
    border: 2px solid var(--color-primary);
}
.image-select-option > img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center center;
}




/*====================
  Delete Product Modal
======================*/
#deleteModal {
    max-width: 650px;
    transform: translate(-50%,0%);
    top:3rem;
    position: fixed;
}

#deleteModal .btn-group {
    margin-top: 1rem;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2rem 0;
}


#addElementModal {
    position:fixed;
    top:38%;
}

.account-form {
    display:grid;
    grid-template-columns: 4fr 5fr;
    min-height: 90vh;
}

.form-container {
    max-width: 500px;
    margin:3rem 0 0 3rem;
    display:block;
    padding:0 1rem;
}

.account-form .left-side h1 {
    text-align: center;
    color: var(--color-light-1);
    margin-bottom: 1rem;
    font-weight: 600;
} 

.account-form .round-img-container {
    width: 250px;
    height: 250px;
    display:block;
    margin:2rem auto;
    border-radius: 400px;
    cursor: pointer;
    box-sizing: border-box;
    overflow:hidden;

}
.account-form .round-img-container img {
    width:100%;
    height:100%;
    box-sizing: border-box;
    object-position: center;
    object-fit: cover;
}
.account-form .image-picker-button {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: 300;
    color: var(--color-primary);
    border-radius: 300px;
    border: 2px solid var(--color-primary);
    box-sizing: border-box;
    text-align: center;

}

.account-type-text {
    color:var(--color-light-4);
    text-align: center;
    margin:0 0 2rem 0;
}

.account-form .btn-group {
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    margin:0.5rem;

}
@media all and (max-width: 767px) {
    .account-form {
        display:block;
    }
    .form-container {
        margin:3rem auto;
    }
}
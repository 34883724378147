.search-section > .section{
    padding: 4rem 2rem;
    display:flex;
}
.search-section > .section > div {
    flex: 1;
}
.search-container {
    width:80%;
}
.image-half {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    align-items: center;
}
.image-half > img {
    margin-right: 2rem;
    display:block;
    width:315px;
}


.container.explore-section {
    padding-bottom: 3rem;
}

.category-box-container {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    max-width:900px;
    column-gap: 10px;
    margin: 3rem auto;
}
.category-box {
    margin: 1rem auto;
    width:175px;
    padding:0.5rem;
    display:block;
    box-sizing: border-box;
    text-decoration: none;
}
.category-box > img {
    display: block;
    margin: 0 auto;
    width:75px;
}
.category-box > hr {
    width: 25px;
    border:none;
    height:1px;
    background-color: var(--color-primary);
    transition: width 0.4s ease;
    margin: 0.75rem auto 0.5rem auto;
}
.category-box:hover > hr {
    width: 135px;

}

.category-box > p {
    color: var(--color-dark-1);
    text-align: center;
    text-decoration: none;
    margin: 0.25rem 0;
}
.container.learn-section {
    padding-bottom: 4.5rem;
}
.info-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 3.5rem;
    
}

.info-box {
    text-align: center;
    max-width: 250px;
    margin:0 0.5rem 2rem;
}
.info-box .img-container {
    width:105px;
    height:105px;
    display:block;
    margin:0 auto;
    border-radius: 100vh;
    overflow:hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 0 4px 4px rgb(20 102 143 / 8%);
    background-color: var(--color-light-1);
}

/* Test with real images */
.info-box img {
    width:100%;
    height:100%;
    padding:18px;
    box-sizing: border-box;
}
.info-box:hover .img-container {
    transform: scale(1.05);
}

.info-box > h4 {
    margin: 0.5rem 0;
    box-sizing: border-box;
}
.info-box > hr {
    box-sizing: border-box;
    height: 1px;
    border:none;
    background-color: var(--color-primary);
    width: 75px;
    margin: 0.5rem auto;
}
.info-box > p {
    margin: 0.25rem 0;
}
.container.cta-section {
    padding-bottom: 2rem;
}
.cta-section > h1 {
    margin-bottom: 0.5rem;
}
.cta-section a {
    margin-top: 1.5rem;
}

@media all and (max-width:800px) {
    .search-section > .section{
        display:flex;
    }
    .image-half {
        display:none;
    }
    .search-container {
        width:100%;
    }
}
@media all and (max-width:650px) {
    .category-box-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media all and (max-width:450px) {
    .category-box-container {
        grid-template-columns: 1fr;
    }
    .search-section > .section {
        padding: 3rem 0rem;
    }
}
.purchased-page .container {
    min-height: calc(100vh - 11.5rem);
}

.purchased-page h2 {
    font-weight: 300;
    text-align: center;
    font-size: 2.1rem;
}
.purchased-page p {
    text-align: center;
    margin:2.5rem 0;
    color: var(--color-dark-4);
}

.purchased-page .check-container {
    display:block;
    position: relative;
    max-width: 300px;
    margin:1rem auto;
}

.purchased-page .check-container hr {
    outline: none;
    border: none;
    height: 2px;
    background-color: var(--color-secondary);
    width: 100%;
    position: absolute;
    transform: translateY(-50%);
    top:50%;
    z-index: 2;
    margin:0;
}

.purchased-page .check-container .check {
    background-color: var(--color-secondary);
    color: var(--color-light-1);
    border:6px solid var(--color-light-1);
    position: relative;
    z-index: 3;
    border-radius: 100vh;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 1.6rem;
}

.purchased-page .container {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.floating-form {
    width:100%;
    max-width: 700px;
    margin:2rem auto;
    padding:2.5rem 5rem;
    border-radius:14px;
    background: linear-gradient(to bottom right, var(--color-light-1), var(--color-light-3));
    box-sizing: border-box;
}

.floating-form a {
    text-align: center;
    color: var(--color-primary);
    box-sizing: border-box;
    width:100%;
    display:inline-block;
    text-decoration: none;
    margin-top:1rem;
}

.floating-form button.btn {
    margin-top:1rem;
}
.floating-form .form-block {
    margin:0.6rem 0;
}

.login-section {
    min-height: calc(100vh - 11.5rem);
}

.purchase-page {
    min-height: calc(100vh - 10rem);
}
.purchase-page .split {
    display:grid;
    grid-template-columns: 1fr 300px;
}
.purchase-page h1, .purchase-page h2 {
    font-weight: 600;
}

.purchase-page .column {
    max-width:800px;
    margin: 1rem auto 0;
    padding:0 1rem;
}
.purchase-page hr {
    width:100%;
    border:none;
    outline: none;
    height:2px;
    background-color: var(--color-primary);
    color: var(--color-primary);
    margin-top: 2rem;
}
.purchase-page .btn-group {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.purchase-page .final-section {
    text-align: center;
}

.purchase-page .split .left-side {
    border-right: 1px solid var(--color-light-4);
}

.purchase-page .input-block.flex {
    display:flex;
    flex-direction: row;
}

.purchase-page .input-block.flex > input {
    flex:1;
    min-width:200px;
    margin-left:1rem;
    margin-right: 1rem;
}
.purchase-page .input-block.flex > input:first-child {
    margin-left:0;
}
.purchase-page .input-block.flex > input:last-child {
    margin-right:0;
}
.purchase-page .product-card-container {
    display: flex;
    height: fit-content;
    position: sticky;
    top: 0;
}

@media all and (max-width:767px) {
    .purchase-page .split {
        display:flex;
        flex-direction: column-reverse;
    }
    .purchase-page .product-card-container {
        position: relative;
        justify-content: center;
    }
    .purchase-page .input-block.flex {
        flex-direction: column;
    }
    .purchase-page .input-block.flex > input {
        margin:0.5rem 0;
    }
    .purchase-page .split .left-side {
        border: none;
    }
}
.product-page {
    display:grid;
    grid-template-columns: 3fr 2fr;
    height:100%;
    min-height: calc(100vh - 10rem);
}
.product-page.display {
    grid-template-columns: 6fr 3fr;
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
}
.product-page.display .product-side {
    padding:1.5rem;
}
.product-page.display .product-details {
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-page.display .profile-display {
    border-radius: 4px;
    background-color: var(--color-light-3);
    display:grid;
    grid-template-columns: 4rem auto;
    width:70%;
    margin:0.5rem auto;
    color: var(--color-dark-3);
    cursor: pointer;
}
.product-page.display .profile-display p {
    margin:0.5rem 0;
}
.product-page.display .profile-display .profile-name { 
    font-weight: 500;
}
.product-page.display .profile-display .profile-username {
    font-size: 0.9rem;
    font-style:italic;
    color:var(--color-dark-2);
}

.product-page.display .profile-display .round-img-container {
    width:3rem;
    height:3rem;
    border-radius: 10rem;
    overflow:hidden;
    margin:0.5rem;

}
.product-page.display .profile-display .round-img-container img {
    width:100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.product-page.display .category {
    /* font-style: italic; */
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color:#d4d4d4;
}
.product-page.display .description {
    color:#d4d4d4;
}
.product-page.display .product-details-header {
    display:flex; 
    flex-direction: row;
    justify-content: space-between;
}
.product-page.display .product-details-header h2 {
    margin:0;
    font-weight: 400;
} 
.product-page.display .price-display {
    color: var(--color-light-1);
    background-color: var(--color-secondary);
    padding:0.6rem;
    border-radius: 6px;
    display:inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    height: fit-content;
}




.add-element-btn.disabled {
    cursor:not-allowed;
    opacity: 0.7;
}


.product-page > div {
    height: 100%;
    box-sizing: border-box;
}
.product-main {
    position: relative;
}

.product-page.edit {
    height: calc(100vh - 3.75rem);
}
.product-page.edit .product-main {
    overflow-x: hidden;
}
.product-page.edit .product-side {
    /* min-height: calc(100vh - 5.5rem); */
    position:relative;
    overflow-x: hidden;

}
/* .product-page.edit .product-side .product-details, .product-page.edit .product-side .config-menu {
    position: sticky;
    top: 4.5rem;
}   */

.product-page.display .product-side {
    margin:2rem;
    min-height: 40vh;
    color: var(--color-light-1);
    box-sizing: border-box;
    height:35rem;
    width:23rem;
    border-radius: 6px;
    position: sticky;
    top: 20px;

}

.product-side .options label {
    color: var(--color-light-1);
}
.product-page.edit .product-side .btn-group {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.product-side > .product-details {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-side > .config-menu {
    color: #fff;
    top:1rem;
}


.unit-display {
    background-color: #eee;
    padding:0 0.75rem;
    font-weight: bold;
    color: var(--color-secondary);
    display:flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid var(--color-secondary);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.cover-img-container {
    display:block;
    margin:0 auto;
    width:350px;
    height:200px;
    overflow: hidden;
    border:2px solid var(--color-primary);
    border-radius: 4px;
}
.cover-img-container img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
    cursor:pointer;

}
.cover-img-container h1 {
    cursor:pointer;
    width:100%;
    height:100%;
    box-sizing: border-box;
    text-align: center;
    color: var(--color-primary);
    margin:0;
    font-size: 3.5rem;
    font-weight: 400;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.config-panel-header {
    margin:0.5rem 0;
    text-transform: capitalize;
}
.config-panel-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.config-panel-body .btn.danger {
    margin-top:2rem;
}
.btn.add {
    font-size:1.25rem;
    padding:0.5rem 1.5rem;
}
/* ////////////////////////// */

.product-page.edit .split-section:hover,
.product-page.edit .split-section:hover .left-side,
.product-page.edit .split-section:hover .right-side,
.product-page.edit .faq-container:hover,
.product-page.edit .header:hover,
.product-page.edit .paragraph:hover,
.product-page.edit .spacer:hover,
.product-page.edit .hr:hover,
.product-page.edit .image:hover,
.product-page.edit .builder-container:hover,
.product-page.edit .split-section.selected,
.product-page.edit .split-section.selected .left-side,
.product-page.edit .split-section.selected .right-side,
.product-page.edit .faq-container.selected,
.product-page.edit .header.selected,
.product-page.edit .paragraph.selected,
.product-page.edit .spacer.selected,
.product-page.edit .hr.selected,
.product-page.edit .builder-container.selected,
.product-page.edit .image.selected {

    border-color: var(--color-primary);
    cursor:pointer;

}
.product-page .hr {
    border:none;
}
.product-page.edit .split-section,
.product-page.edit .faq-container,
.product-page.edit .header,
.product-page.edit .paragraph,
.product-page.edit .spacer,
.product-page.edit .hr,
.product-page.edit .builder-container,
.product-page.edit .image {
    border: 2px solid #ccc;

}
.product-page.edit .split-section .left-side {
    border-right:1px solid #ccc;
}
.product-page.edit .split-section .right-side {
    border-left:1px solid #ccc;
}

.product-page .split-section,
.product-page .split-section .left-side,
.product-page .split-section .right-side,
.product-page .faq-container,
.product-page .header,
.product-page .paragraph,
.product-page .spacer,
.product-page .hr,
.product-page .builder-container,
.product-page .image {
    box-sizing: border-box;
    margin:0.1rem 0;

}
.product-page .header,
.product-page .paragraph,
.product-page .faq-answer {
    white-space: pre-line;
}
.product-page.display .faq-container,
.product-page.display .split-section .left-side,
.product-page.display .split-section .right-side {
    margin:0.25rem 0;
}

.product-page.display .image,
.product-page.display .split-section,
.product-page.display .builder-container,
.product-page.display .header,
.product-page.display .paragraph {
    margin:0.75rem 0;
}
.product-page.display .hr {
    margin: 0.75rem auto;
}

.product-page .split-section .left-side,
.product-page .split-section .right-side {
    margin:0;
}
.product-main hr.hr {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
}
.product-page .side-faq-module-container {
    border: 2px solid var(--color-primary);
    border-radius: 6px;
    display:grid;
    grid-template-columns: 1fr 2rem;
    margin: 0.5rem 0;
}
.product-page .side-faq-module-container .input {
    display:inline-block;
    width:90%;
}
button.cancel-btn {
    color: var(--color-light-1);
    display:block;
    margin: 0.3rem auto;
}
.cancel-btn:hover {
    color: var(--color-danger);
}

.product-main .split-section {
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin:0.3rem 0;
}

.product-main .split-section.split1 {
    grid-template-columns: 2fr 1fr;
}

.product-main .split-section.split3 {
    grid-template-columns: 1fr 2fr;
}

.product-page.edit .builder-container,
.product-page.edit .split-section .left-side,
.product-page.edit .split-section .right-side {
    padding: 0.5rem;
}

.product-page .split-section .left-side {
    padding-right: 0.5rem;
}
.product-page .split-section .right-side {
    padding-left: 0.5rem;
}
.product-main .faq-container {
    width:100%;
    margin: 0.5rem auto;
    display:block;

}
.product-main .faq-container .faq-module {
    width:100%;
}
.product-main .faq-container .faq-module .faq-question {
    background-color: var(--color-dark-2);
    color:var(--color-light-1);
    padding:0.85rem;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    cursor:pointer;
    border-bottom: 1px solid #ccc;
}
.faq-module input {
    display:none;
}
.faq-module .faq-question i {
    margin-right: 0.3rem;
    transition: transform 0.2s ease;
    transform-origin: center;
}
.faq-module input:checked + .faq-question i {
    transform:rotate(180deg);
}
.product-main .faq-container .faq-module .faq-answer {
    display: block;
    background-color: var(--color-light-2);
    max-height:0rem;
    height:100%;
    padding:0 0.85rem;
    transition: max-height 0.5s ease, padding 0.3s ease;
    overflow:hidden;
    box-sizing: border-box;
}


.faq-module input:checked + .faq-question + .faq-answer {
    max-height:10rem;
    padding:0.85rem;

}

.product-main .spacer.space1 {
    height:20px;
}
.product-main .spacer.space2 {
    height:35px;
}
.product-main .spacer.space3 {
    height:45px;
}
/* ////////////////////////// */


.status-bar-product {
    position: fixed;
    z-index: 100;
    top:3.75rem;
    width:100%;
    background-color: var(--color-primary);
    color: var(--color-light-1);
    text-align: center;
    max-height: 0px;
    height: 100%;
    transition: max-height 0.2s ease;
    overflow: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.status-bar-product.open {
    max-height: 3rem;
}

.add-modal-list {
    display: grid; 
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 0.75rem; 
    justify-content: space-between; 
    padding:0.5rem;
}

.add-element-btn {
    display:flex;
    width:140px;
    height:100px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius:4px;
    border:1px solid #ccc;
    cursor:pointer;
    transition: border-color 0.1s ease, color 0.1s ease;
}
.add-element-btn:hover {
    border-color: var(--color-primary);
    color: var(--color-primary);
}



.open-add-element-btn {
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    font-size:1.5rem;
    text-align: center;
    cursor:pointer;
    border-radius: 4px;
    padding:1rem;
    display:block;
    width:90%;
    max-width: 350px;
    margin: 1.5rem auto 0;
}


.nested-add-element-btn {
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    font-size:1.5rem;
    text-align: center;
    cursor:pointer;
    border-radius: 4px;
    padding:1rem;
    display:block;
    width:75%;
    margin: 0 auto;
}
.nested-add-element-btn:hover, .open-add-element-btn:hover {
    background-color: #648DE522;
}

.inbetween-add-element-btn {
    position: relative;
    cursor: pointer;
    opacity:0.2;
    padding:0.5rem 0;
    transition: opacity 0.1s ease;
}
.inbetween-add-element-btn:hover {
    opacity:1;
}

.inbetween-add-element-btn > div {
    position: absolute;
    left:-10px;
    top:50%;
    transform: translateY(-50%);
    color: var(--color-light-1);
    font-size: 1.1rem;
    background-color: var(--color-primary);
    padding:1px 6px;
    border-radius: 50px;
}
.inbetween-add-element-btn > hr {
    border:none;
    border-bottom: 2px dashed var(--color-primary);
    box-sizing: border-box;
}



@media all and (max-width:850px) {
    .product-page.display {
        grid-template-columns: 1fr;
    }
    .product-page.display .profile-display {
        width:100%;
    }
    .product-page.display .product-side {
        width:90%;
        margin:2rem auto;
        max-width:25rem;
    }
}


@media all and (max-width:767px) {

    .product-main .split-section {
        display:block;
    }
}
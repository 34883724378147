

.account-type-section {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
}
.account-type-section .container {
    height:100%;
    box-sizing: border-box;
    min-height: 90vh;
}


.account-type-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}


.account-selection {
    width:100%;
    max-width: 300px;
    border-radius: 4px;
    text-align: center;
    border:2px solid var(--color-dark-2);
    box-sizing: border-box;
    cursor:pointer;
    transition:border-color 0.15s ease, transform 0.2s ease;
    margin:1rem 2rem;
    padding:2rem 1rem;
    color:var(--color-light-1);
}

.account-selection:hover {
    border-color: var(--color-primary);
    transform: scale(1.02);
}

.account-selection h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin:0.75rem 0;
}
.account-selection p {
    color: var(--color-light-4);
}

.account-selection .img-container {
    width:145px;
    margin:0 auto 1rem;
    display:block;
}

.account-selection .img-container img {
    width:100%;
    height:100%;
}
/* //////////////////////////////////////////// */

.form-section {
    display:grid;
    grid-template-columns: 4fr 5fr;
    min-height: 90vh;
}

.form-container {
    max-width: 500px;
    margin:3rem 0 0 3rem;
    display:block;
    padding:0 1rem;
}

.form-section .left-side h1 {
    text-align: center;
    color: var(--color-light-1);
    margin-bottom: 1rem;
    font-weight: 600;
} 

.form-section .round-img-container {
    width: 250px;
    height: 250px;
    display:block;
    margin:1rem auto;
    border-radius: 400px;
    cursor: pointer;
    box-sizing: border-box;
    overflow:hidden;

}
.form-section .round-img-container img {
    width:100%;
    height:100%;
    box-sizing: border-box;
    object-position: center;
    object-fit: cover;
}
.form-section .image-picker-button {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: 300;
    color: var(--color-primary);
    border-radius: 300px;
    border: 2px solid var(--color-primary);
    box-sizing: border-box;
    text-align: center;

}

.account-type-text {
    color:var(--color-light-4);
    text-align: center;
    margin:0 0 2rem 0;
}


@media all and (max-width: 600px) {
    .form-section {
        display:block;
    }
    .form-container {
        margin:3rem auto;
    }
}
/* //////////////////////////// */
.screen-section {
    overflow-x: hidden;
    box-sizing: border-box;
    position: relative;
    min-height: 90vh;
    height:fit-content;
    /* display:flex;
    flex-direction:row; */

}

.screen-section > div {
    min-width:100vw;
    max-width: 100vw;
    position:absolute;
    transition: transform 0.5s ease;
    box-sizing: border-box;
}
.screen-section.account-type {
    transform:translateX(0);
    overflow: hidden;
}


.account-type-section {
    transform: translateX(0%);
}
.form-section {
    transform: translateX(100%);
}
.screen-section.form > .form-section {
    transform: translateX(0);
}
.screen-section.form > .account-type-section {
    transform: translateX(-100%);
}
nav hr {
    background-color: #ccc;
    border:none;
    padding:0;
    height:1px;
    margin:0;
}

.primary-nav {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 16px; */
    padding:0.25rem 1rem 1px;
    align-items: center;
    box-sizing: border-box;
}
.primary-nav .nav-logo {
    padding: 14px 0;
    text-decoration:none;
    color: var(--color-dark-1);
}
.primary-nav .nav-item {
    padding: 14px 0.75rem;
    box-sizing: border-box;
    text-decoration:none;
    color: var(--color-dark-1);
    cursor: pointer;
    position: relative;
    bottom: -2px;
    border-bottom: 2px solid transparent;
}
.primary-nav .nav-item a {
    text-decoration:none;
    color: inherit;
}
.primary-nav .nav-item:hover {
    border-bottom: 2px solid var(--color-primary);
    color: var(--color-primary);
}

.primary-nav > div:last-child {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dropdown {
    position: relative;

}

.dropdown .dropdown-toggle {
    width:100%;
    height: 100%;
    box-sizing: border-box;
}
.dropdown .dropdown-menu {
    display:none;
    position:absolute;
    z-index: 30;
    /* top: 3.45rem; */
    margin:0;
    padding:0;
    min-width: 7.5rem;
    list-style: none;
    background-color: var(--color-light-1);
    border:1px solid var(--color-light-3);
    border-radius: 3px;
    right:-15px;
    box-sizing: border-box;
}
.dropdown .dropdown-toggle:hover + .dropdown-menu {
    display:block;
}
.dropdown-menu:hover {
    display:block;
}
.dropdown-menu .dropdown-item {
    width:100%;
    box-sizing: border-box;
    background-color: var(--color-light-1);
    box-sizing: border-box;

}
.dropdown-menu .dropdown-item:hover, .logout-btn:hover {
    color: var(--color-light-1);
    background-color: var(--color-primary);

}

.dropdown-menu .dropdown-item a {
    width:100%;
    display: inline-block;
    padding:0.45rem 0.65rem;
    text-decoration:none;
    color: inherit;
    box-sizing: border-box;

}

.logout-btn {
    width:100%;
    border:none;
    background-color: transparent;
    font-size: 1rem;
    padding:0.45rem 0.65rem;
    text-align:left;
    cursor:pointer;
    color: var(--color-dark-1);

}

.nav-item.account {
    padding: 7px .75rem;
}

.nav-item.account > a {
    display:flex;
    flex-direction: row;
    align-items: center;
}
.profile-section-nav {
    display: contents;
    margin: 0;
}
.profile-section-nav > div {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: .25rem;
}
.profile-section-nav > p {
    margin:0 4px 0 0;
}
.profile-section-nav > div > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
/*///////////////////////////////////////////////////////*/
.secondary-nav {
    position: relative;
    display:flex;
}
.secondary-nav .nav-item-list{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* border-bottom: 1px solid #ccc; */
    scroll-behavior: smooth;
}
.secondary-nav .nav-item-list::-webkit-scrollbar {
    display: none;
}

.secondary-nav a {
    text-decoration:none;
    color: var(--color-dark-1);
    padding: 0.5rem;
    white-space: nowrap;
}
.secondary-nav a:hover {
    color: var(--color-primary);
}

.secondary-nav .nav-scroll {
    display:flex;
    flex-direction: column;
    justify-content: center;
    position:absolute;
    cursor:pointer;
    transition:color 0.1s ease;
    height:100%;
    padding:0 0.25rem;
    color:var(--color-dark-2);
    z-index:20;
}
.secondary-nav .nav-scroll:hover {
    color: var(--color-primary);
}
.secondary-nav .nav-scroll.left {
    left:1rem;
    background: linear-gradient(to right, #fff, #fff, #fff, #fff0);
    padding-right:0.85rem;
}

.secondary-nav .nav-scroll.right {
    right:1rem;
    background: linear-gradient(to left, #fff, #fff, #fff, #fff0);
    padding-left:0.85rem;
}








.edit-nav {
    height: 3.75rem;
    padding: 0 2rem;
    position: fixed;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    background: var(--color-light-1);
    box-shadow: 0px 4px 6px #65627624;
}
.edit-nav > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
}
.edit-nav > div > div {
    display:flex;
}
.edit-nav > div > div > button {
    margin-right: 1rem;
}

.edit-nav span {
    align-self: center;
    color: var(--color-dark-4);
    font-size: 0.9rem;
}
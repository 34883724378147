.section.account-page {
    display: grid;
    grid-template-columns: 25rem 1fr;
    min-height: calc(100vh - 11.5rem);
}

.account-container {
    max-height: 80vh;
    border-radius: 6px;
    color: var(--color-light-1);
    text-align: center;
    height:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.account-container .round-img-container {
    width:180px;
    height: 180px;
    border-radius: 200px;
    overflow:hidden;
    margin: 1rem auto;
    display: block;
}
.account-container .round-img-container img {
    object-fit: cover;
    object-position: center;
    width:100%;
    height: 100%;
}
.account-container .name {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 0;
}

.account-container .username {
    font-weight: 400;
    margin: 1rem 0;
}
.account-container .email {
    margin-top:1rem;
    font-weight: 400;
    color: var(--color-light-4);
}

.account-container .seller-link {
    color: var(--color-secondary);
    font-weight: 500;
    cursor:pointer;
}

.service-side {
    margin-left:1rem;
}
.service-side .title {
    font-weight: 600;

}

.service-side .service-list {
    display:flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
}

.service-side .title-bar {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.service-side .title-bar button {
    align-self: center;
    font-size: 1.24rem;
    padding: .4rem 0.8rem;
}


@media all and (max-width: 850px) {
    .section.account-page {
        display: block;
    }
}
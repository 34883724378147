.search-page {
    min-height: calc(100vh - 10rem);
}

.result-area {
    min-height: 45vh;
}
.list {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.results-header {
    color: var(--color-dark-3);
}
.no-results-header {
    color: var(--color-dark-3);
    text-align: center;
}


@media all and (max-width: 1000px) {
    .category-header img {
        max-width: 350px;
    }
    .category-header .left-side {
        max-width: 400px;
    }
}
@media all and (max-width: 800px) {
    .category-header img {
        display:none;
    }
}
.category-page {
    min-height: calc(100vh - 10rem);
}

.category-name {
    text-transform: capitalize;
    color: var(--color-light-1);
    display:inline-block;
    margin-bottom: 0.5rem;
}
.category-header.container.gradient {
    padding: 0;
    position: relative;
}
.category-header .split {
    display:block;
}
.category-header .left-side {
    max-width: 500px;
    height:250px;
}
.category-header .left-side .description {
    color:var(--color-light-4);
}
.category-header img {
    max-width: 450px;
    display:block;
    margin-left: auto;
    position:absolute;
    right:0;
    bottom:0;
}
.result-area {
    min-height: 45vh;
}
.list {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.results-header {
    color: var(--color-dark-3);
}
.no-results-header {
    color: var(--color-dark-3);
    text-align: center;
}



@media all and (max-width: 1000px) {
    .category-header img {
        max-width: 350px;
    }
    .category-header .left-side {
        max-width: 400px;
    }
}
@media all and (max-width: 800px) {
    .category-header img {
        display:none;
    }
}